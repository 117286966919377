import Cookies from "js-cookie";
import axios from "axios";

import { ContractConfig, GameInfo, PriceInfo, SignedTxnInfo } from "../models";

export const getContractConfig = (): ContractConfig => {
  return {
    contract: {
      name: Cookies.get("CONTRACT_NAME"),
      network: Cookies.get("CONTRACT_NETWORK"),
      chain_id: Cookies.get("CONTRACT_CHAIN_ID"),
      lcd_url: Cookies.get("CONTRACT_LCD_URL"),
      address: Cookies.get("CONTRACT_ADDRESS")
    }
  };
}

export const getPriceInfo = async (): Promise<PriceInfo> => {
  const response = await axios.get("/api/v1/price-info");
  return response.data;
}

export const getGameInfo = async (): Promise<GameInfo> => {
  const response = await axios.get("/api/v1/game-info");
  return response.data;
}

export const signTxn = async (userWallet: string, gameId: number, amount: number): Promise<SignedTxnInfo> => {
  const response = await axios.post("/api/v1/sign-txn", {
    user_wallet: userWallet,
    game_id: gameId,
    amount: amount
  });

  return response.data;
}
