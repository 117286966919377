import { LCDClient } from "@terra-money/feather.js";
import { ConnectResponse, WalletResponse } from "@terra-money/wallet-kit";

import { CONTRACT_NAME } from "../constants";
import config from "../refs.terrain.json";

export const getLCDClient = (wallet: WalletResponse): LCDClient => {
  return new LCDClient(wallet.network);
}

export const getChainID = (connected: ConnectResponse): string => {
  if (!connected?.network) return "";

  const contractChains = (config as any)[connected.network];
  return Object.keys(contractChains)[0];
};

export const getContractAddress = (connected: ConnectResponse) => {
  return (config as any)[(connected.network ?? "localterra")]?.[getChainID(connected)]?.[CONTRACT_NAME].contractAddresses?.default;
}

export const getOwnerAddress = (connected: ConnectResponse) => {
  return connected.addresses[getChainID(connected)];
}
