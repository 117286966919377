import { Coins } from "@terra-money/feather.js";
import { ConnectResponse, WalletResponse } from "@terra-money/wallet-kit";

import { getLCDClient, getChainID } from "../utils/TerraUtils";

export const WalletService = () => {
  const getWalletAddress = (connected: ConnectResponse | undefined): string => {
    // Get out of dodge early if we don't have a usable wallet
    if (!connected) throw new Error("No wallet is connected");
    return connected.addresses[getChainID(connected)]
  }

  const getOwnerBalance = async (wallet: WalletResponse, connected: ConnectResponse | undefined): Promise<Coins> => {
    // Get out of dodge early if we don't have a usable wallet
    if (!connected) throw new Error("No wallet is connected");

    //
    // This gets the balance in coins for the owners wallet
    // BUT should it be "spendableBalances" instead?!?!?
    //
    const [coins] = await getLCDClient(wallet).bank.balance(connected.addresses[getChainID(connected)]);
    return coins;
  }

  return {
    getWalletAddress,
    getOwnerBalance,
  };
}
