import config from "refs.terrain.json";

import { getContractConfig } from "utils/HostUtils";

const contractConfig = getContractConfig();

let defaultContractAddress: string = "";

export const CONTRACT_NAME = (contractConfig.contract?.name ?? process.env.REACT_APP_CONTRACT_NAME as string);
export const CONTRACT_NETWORK = (contractConfig.contract?.network ?? process.env.REACT_APP_CONTRACT_NETWORK as string);
export const CONTRACT_CHAIN_ID = (contractConfig.contract?.chain_id ?? process.env.REACT_APP_CONTRACT_CHAIN_ID as string);
export const CONTRACT_LCD_URL = (contractConfig.contract?.lcd_url ?? process.env.REACT_APP_CONTRACT_LCD_URL as string);

try {
  defaultContractAddress = (config as any)[CONTRACT_NETWORK][CONTRACT_CHAIN_ID][CONTRACT_NAME].contractAddresses.default;
} catch {}

export const CONTRACT_ADDRESS = contractConfig.contract?.address ?? defaultContractAddress;

export const MINIMUM_TICKETS_TO_DRAW = 50;
