import ReactDOM from "react-dom";
import { getInitialConfig, WalletProvider } from "@terra-money/wallet-kit";

import 'bootstrap/dist/css/bootstrap.css';

import App from "./App";

import "./assets/css/winlunc.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import { CONTRACT_LCD_URL } from './constants';

if (CONTRACT_LCD_URL !== "http://localhost:1317" && process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

getInitialConfig().then((config) => {
  ReactDOM.render(
    <WalletProvider defaultNetworks={config}>
      <App />
    </WalletProvider>,
    document.getElementById("root")
  );
});
