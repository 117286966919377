import React, { useState, useEffect } from "react";

import { useWallet, useConnectedWallet, WalletStatus } from "@terra-money/wallet-kit";
import { Coins } from "@terra-money/feather.js";

import Header from "../../assets/imgs/bg/bg-header.png";
import PlayRequirements from "../../assets/imgs/bg/play-requirements.png";
import Car from "../../assets/imgs/bg/bglambo.png";
import Boat from "../../assets/imgs/bg/bgboat.png";
import Logo from "../../assets/imgs/logo.png";
import Tokens from "../../assets/imgs/tokens/winlunc-terra-classic.png";
import GameTokens from "../../assets/imgs/game-tokens/game-token-winlunc-terraclassic.png";
import PrizeToken from "../../assets/imgs/tokens/prizetokenbg.png";
import BuyWithLUNC from "../../assets/imgs/currency/lunc-buy.png";

import { WalletService, ContractService } from "services";
import { DenomPrice, GameInfo } from "models";

import { MINIMUM_TICKETS_TO_DRAW } from "../../constants";

const { getWalletAddress, getOwnerBalance } = WalletService();
const { getGameInfo, getPriceInfo, buyTickets } = ContractService();

function Home() {
  // Wallet & Contract State
  const wallet = useWallet();
  const { status, connect, disconnect } = wallet;
  const connectedWallet = useConnectedWallet();

  // Can they play? (Must have station wallet!!)
  const unableToPlay = (!(window as any).station || (window as any).station instanceof HTMLElement);

  // Page State
  const [connectedWalletAddress, setConnectedWalletAddress] = useState<string>("");
  const [coinPrice, setCoinPrice] = useState<DenomPrice | null>(null);
  const [ticketPrice, setTicketPrice] = useState<DenomPrice | null>(null);
  const [ownerBalance, setOwnerBalance] = useState<Coins | null>(null); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [currentGameInfo, setCurrentGameInfo] = useState<GameInfo | null>(null);
  const [timeTillDraw, setTimeTillDraw] = useState<string>("00:00:00");
  const [amountOfTickets, setAmountOfTickets] = useState<number>(0);
  const [valueOftickets, setValueOftickets] = useState<number>(0);

  async function connectWalletHandler() {
    console.log("Home->connectWalletHandler");

    if (unableToPlay)
      window.alert("To run this dApp you must use the Chrome Browser with the Terra Station Wallet extension installed and configured with a valid Terra Classic wallet.");
    else if (status === WalletStatus.NOT_CONNECTED) {
      await connect();
    }
  }

  async function disconnectWalletHandler() {
    console.log("Home->disconnectWalletHandler");

    let v = window.confirm(`Are you sure you want to disconnect your wallet (${getWalletAddress(connectedWallet)})`);

    console.log({ v });

    if (v && status === WalletStatus.CONNECTED) {
      disconnect();
      await resetGameState();
    }
  }

  async function resetGameState() {
    console.log("Home->resetGameState");

    setConnectedWalletAddress("");

    // Fetch the Ticket & Coin Price
    const priceInfo = await getPriceInfo();

    setTicketPrice(priceInfo.ticket);
    setCoinPrice(priceInfo.coin);

    // Fetch the Current Game Info
    const newCurrentGameInfo = await getGameInfo(wallet, connectedWallet);
    setCurrentGameInfo(newCurrentGameInfo);

    setOwnerBalance(null);
    setAmountOfTickets(0);
    setValueOftickets(0);
  }

  async function refreshGameState() {
    console.log("Home->refreshGameState");

    // Fetch the Ticket & Coin Price
    const priceInfo = await getPriceInfo();

    setTicketPrice(priceInfo.ticket);
    setCoinPrice(priceInfo.coin);

    // Fetch the Current Game Info
    const newCurrentGameInfo = await getGameInfo(wallet, connectedWallet);
    setCurrentGameInfo(newCurrentGameInfo);

    const currentOwnerBalance = await getOwnerBalance(wallet, connectedWallet);
    setOwnerBalance(currentOwnerBalance);

    // Reset the amount and value of tickets
    setAmountOfTickets(0);
    setValueOftickets(0);

    console.log(`Home->refreshDrawState: coinPrice=${JSON.stringify(priceInfo.coin)}, ticketPrice=${JSON.stringify(priceInfo.ticket)}, CurrentDraw=${JSON.stringify(newCurrentGameInfo)}`);
  }

  function countDownToNow() {
    //
    // This assumes that the lottery is run each day UTC
    //
    // Therefore this needs to be based from the timezone of the calling client and the
    // time offset from UTC when the actual lottery is run
    //

    let now = new Date();

    let hour = now.getUTCHours();
    let mins = now.getUTCMinutes();
    let secs = now.getUTCSeconds();

    let laterHour = 23;
    let laterMins = 59;
    let laterSecs = 59;

    let remainingHour = laterHour - hour;
    let remainingMins = laterMins - mins;
    let remainingSecs = laterSecs - secs;

    return `${remainingHour.toString().length === 1 ? 0 + remainingHour.toString() : remainingHour.toString()}:${remainingMins.toString().length === 1 ? 0 + remainingMins.toString() : remainingMins.toString()}:${remainingSecs.toString().length === 1 ? 0 + remainingSecs.toString() : remainingSecs.toString()}`;
  }

  function handleTicketNumber(e: any) {
    const { value } = e.target;

    console.log(`Home->handleTicketNumber: value = ${value}`);

    if (!value) return;

    setAmountOfTickets(parseInt(value));
    setValueOftickets((coinPrice?.price ?? 0) <= 0 ? 0 : Math.round(((ticketPrice?.price ?? 0) * parseInt(value)) / (coinPrice?.price ?? 1)));
  }

  async function buyTicketsHandler(e: any) {
    // What about the balance of the clients wallet??

    try {
      if (!connectedWallet) throw new Error("Please connect a wallet");
      if (!amountOfTickets) throw new Error("Please input the no of tickets you want to purchase");

      ShowOverlay();

      if (coinPrice && ticketPrice) {
        // Buy the ticket(s)
        console.log(`Home->buyTicketsHandler: GameID=${currentGameInfo?.id}, ticketsValue=${amountOfTickets}, coinPrice=${JSON.stringify(coinPrice)}, ticketPrice=${JSON.stringify(ticketPrice)}`);
        const { txhash, success } = await buyTickets(wallet, connectedWallet, (currentGameInfo?.id ?? 0), amountOfTickets);
        console.log(`Home->buyTicketsHandler: txhash=${txhash}, success=${success}`);

        // Re-fetch the Game state
        await refreshGameState();

        HideOverlay();
      }
    } catch (err: any) {
      HideOverlay();
      console.log(err.message);
    }
  }

  useEffect(() => {
    console.log("Home->useEffect[]");

    (async () => {
      try {
        const interval = setInterval(() => {
          setTimeTillDraw(countDownToNow());
        }, 1000);

        return () => clearInterval(interval);
      } catch (err: any) {
        console.log({ Error: err.message });
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("Home->useEffect[connectedWallet]");

    if (!currentGameInfo) {
      console.log("Home->useEffect[connectedWallet] - resetGameState");

      resetGameState()
        .then(() => {
          console.log("Home->useEffect[connectedWallet] - resetGameState - refreshGameState");
          if (connectedWallet) {
            setConnectedWalletAddress(getWalletAddress(connectedWallet));
            refreshGameState();
          }
        });
    }
    else {
      console.log("Home->useEffect[connectedWallet] - refreshGameState");

      if (connectedWallet) {
        setConnectedWalletAddress(getWalletAddress(connectedWallet));
        refreshGameState();
      }
    }
  }, [connectedWallet]); // eslint-disable-line react-hooks/exhaustive-deps

  function ShowOverlay() {
    let rootDiv = document.getElementById("overlay");

    if (rootDiv)
      rootDiv.style.display = "block";
  }

  function HideOverlay() {
    let rootDiv = document.getElementById("overlay");

    if (rootDiv)
      rootDiv.style.display = "none";
  }

  function getCurrentGameWinningPayout(): string {
    let payout = 0

    if (currentGameInfo) {
      if ((currentGameInfo.game_no_of_tickets ?? 0) > MINIMUM_TICKETS_TO_DRAW)
        payout = currentGameInfo.game_winning_payout ?? 0;
      else {
        payout = MINIMUM_TICKETS_TO_DRAW * ((ticketPrice?.price ?? 0) / (coinPrice?.price ?? 1));
      }
    }

    return payout.toLocaleString("en-US", {maximumFractionDigits:0});
  }

  function openLinkInNewTab(url: string) {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  }

  return (
    <>
      <div className="smart-bg hide_on_small"></div>
      <div className="smart-bg hide_on_small"></div>
      <div className="smart-bg"></div>
      <div className="smart-bg fth4"></div>
      <div className="smart-bg fth5"></div>
      <div className="footer-bg"></div>
      <div className="_bg_section"></div>

      <main className="position-relative mainarea z-1">
        <div id='overlay' className="overlay">
          <p>PROCESSING</p>
        </div>
        {/*  Backgrounds Starts */}
        <div className="_bg_header overflow-hidden">
          <img src={Header} alt="" className="w-100 darkimg" />
          <div className="colorful_grafitti w-100 position-absolute bottom-50 start-0"></div>
          <div className="golden_grafitti position-absolute top-0 start-0"></div>
          <div className="golden_grafitti position-absolute top-0 end-0"></div>
          <div className="blur_grafitti w-100 position-absolute top-0 start-0"></div>
        </div>
        <div className="_bg_header2 overflow-hidden d-flex">
          <div className="_wrapper d-flex justify-content-center align-items-baseline position-relative _inner">
            <img src={Car} className="position-relative bg_lambo" alt="" />
            <img src={Boat} className="bg_boat position-relative" alt="" />
          </div>
          <div className="lights_grafitti w-100 position-absolute top-0 start-0"></div>
        </div>
        {/* Backgrounds Ends */}

        {/* Header Starts */}
        <div className="header py-3">
          <div className="_wrapper position-relative">
            <div className="header_main">
              <div className="_sectionp">
                <div className="d-flex justify-content-center">
                  {unableToPlay ?
                    (
                      <img className="img-fluid" src={PlayRequirements} alt="" />
                    )
                    :
                    (
                      <button className="btn px-2 py-1 btn-light border-2 rounded-4 border-black btn-winlunk fw-light" onClick={wallet.status === WalletStatus.NOT_CONNECTED ? connectWalletHandler : disconnectWalletHandler}>
                        {connectedWallet ? (
                          <>
                            {connectedWalletAddress.substring(0, 20) + "..."}
                          </>
                        ) : (
                          <>
                            Connect <span className="fw-bold">Compatible</span> Wallet
                          </>
                        )}
                      </button>
                    )
                  }
                </div>
              </div>
              <div className="d-flex flex-column align-items-center position-relative z-2 mt-3">
                <img className="logo" src={Logo} alt="WinLUNC - Terra Classis" />
                <h2 className="fw-bold text-danger">PRIZE POOL</h2>
                <div className="px-4 py-2  position-relative frame_round d-flex justify-content-center align-items-center">
                  <h5 className="position-absolute m-0 p-0 fw-bold"><span className="text-light">{getCurrentGameWinningPayout()}</span> LUNC</h5>
                </div>
              </div>
              <div className="_tokens_img d-flex justify-content-center position-relative z-1">
                <img src={Tokens} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Header Ends /*}

        {/* Contents Starts */}
        <div className="w-100 position-relative z-1">
          <div className="_wrapper">
            <div className="_sectionp">
              <h4 className="text-center hd-sm mb-0">
                {currentGameInfo
                  ? (<>Draw Number <span className="text-danger fw-bold">{currentGameInfo?.id}</span> in</>)
                  : (<>Drawing in</>)
                }
              </h4>
              {/* Counter Starts */}
              <div className="timecounter d-flex justify-content-center align-items-center">
                <span className="word">{timeTillDraw[0]}</span>
                <span className="word">{timeTillDraw[1]}</span>
                <span className="spacer">:</span>
                <span className="word">{timeTillDraw[3]}</span>
                <span className="word">{timeTillDraw[4]}</span>
                <span className="spacer">:</span>
                <span className="word">{timeTillDraw[6]}</span>
                <span className="word">{timeTillDraw[7]}</span>
              </div>
              {/* Counter Ends */}

              {/* Tickets in Draw Start */}
              {wallet.status === WalletStatus.CONNECTED && (
                <p className="text-center fw-bold mt-0 text-xsm">You have {currentGameInfo?.no_of_owner_tickets} entries in the next draw</p>
              )}
              {/* Tickets in Draw End */}

              {/* Entry Form Starts */}
              <div className="entry_form rounded-4 mt-0 mt-lg-4 overflow-hidden">
                <div className="_e_header py-lg-3 py-2 d-flex justify-content-center align-items-baseline">
                  <h3 className="fw-bold mb-0">EACH ENTRY {new Intl.NumberFormat("en-us", { style: "currency", currency: "USD" }).format((ticketPrice?.price ?? 0))} </h3>
                  <p className="mb-0 ms-2">(USD)</p>
                </div>
                <div className="e_body pt-2 pt-lg-4 pb-1 px-lg-4 px-2">
                  <form action="" onSubmit={e => e.preventDefault()}>
                    <div className="row align-items-center mb-2">
                      <div className="col-8">
                        <label className="fw-bold" htmlFor="entryquantity">Select entry quantity</label>
                      </div>
                      <div className="col-4">
                        <select onChange={handleTicketNumber} value={amountOfTickets} className="custom-select form-select rounded-0">
                          <option value="0">Choose No. of Tickets</option>
                          {Array.from({ length: 20 }).map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row align-items-center mb-2">
                      <div className="col-8 d-flex align-items-center">
                        <label className="fw-bold" htmlFor="entryquantity">Buy using</label>
                        <div className="d-flex ms-3">
                          <div className="d-flex flex-column align-items-center me-2">
                            <img src={BuyWithLUNC} width="28px" alt="" />
                            <p className="mb-0 currency">LUNC</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <select className="custom-select form-select rounded-0" id="entryquantity" defaultValue={"0"}>
                          <option value="0">LUNC</option>
                        </select>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-8">
                        <label htmlFor="purchase" className="fw-bold">Required for purchase</label>
                      </div>
                      <div className="col-4">
                        <input type="text" className="form-control custom-input" id="purchase" placeholder="000,0000,000" readOnly value={`${valueOftickets} LUNC`} />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-3 ">
                      {wallet.status === WalletStatus.CONNECTED && (
                        <>
                          <button type="button" className="btn btn-lg px-5 py-1 rounded-4 fw-bold submit-btn" onClick={buyTicketsHandler}>BUY NOW</button>
                          <br />
                        </>
                      )}
                      {wallet.status !== WalletStatus.CONNECTED && (
                        <p className="text-capitalize text-xsm text-black-50 mt-1 mb-1">connect your wallet to purchase entries</p>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              {/* Entry Form Ends */}

              <div className="mt-2">
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img src={GameTokens} width="30px" alt="" />
                  <h6 className="fw-bold text-black-50 mb-0 ms-2">You are playing WinLUNC on the Terra Classic Chain</h6>
                </div>
                <p className="text-center text-xsm line-height-1 word-spacing mt-3 mb-0">
                  WinLUNC is a daily lottery running on the BNB & Terra Classic chains and offers players the
                  chances to win awesome prizes in LUNC while contributing to the burning efforts to reduce
                  the LUNC supply. With a draw every 24 hours at midnight UTC, and odds of winning that are
                  1000s of times better than a state lottery, there are more than ever before at WinLUNC. At
                  only {new Intl.NumberFormat("en-us", { style: "currency", currency: "USD" }).format((ticketPrice?.price ?? 0))} USD per entry, don't miss your chances to be a winner!
                </p>

                {/*  Token Starts */}
                <div className="p-3 tokennew_main">
                  <div className="d-flex justify-content-between align-items-center position-relative mt-lg-2 _tokenew">
                    <div className="w-left position-relative">
                      <h1 className="_stateh d-inline-block fw-bold">50</h1>
                    </div>
                    <div className="w-center">
                      <img src={PrizeToken} className="" alt="" />
                    </div>
                    <div className="w-right position-relative">
                      <h1 className="_stateh d-inline-block fw-bold">50</h1>
                    </div>
                  </div>
                </div>
                <div className=" text-center fw-semibold text-sm tokennew_text position-relative">
                  <div className="position-absolute w-100 d-flex justify-content-between tokennew_text_pos">
                    <p className="_stat_p _stat_p1 ">OF PRIZE POOL <br /> PAID TO DAILY WINNER</p>
                    <p className="_stat_p _stat_p2 ">OF PRIZE POOL <br /> SENT TO BURN WALLET</p>
                  </div>
                </div>

                <div className="mt-4 mt-lg-3 text-center">
                  <h1 className="fw-bold mb-3 _text_dark">{(currentGameInfo?.total_paid_burn ?? 0).toLocaleString("en-US", {maximumFractionDigits:0})}</h1>
                  <h4 className="fw-semibold text-dark-emphasis"><span className="text-danger">TOTAL BURNED</span> by WinLUNC</h4>
                  <div className="d-flex justify-content-center flex-lg-row flex-column mt-4 w-mbl-70 m-auto">
                    <button type="button" className="btn _btn_grey border-0 rounded-5 me-lg-5 text-sm mb-lg-0 mb-3" onClick={() => openLinkInNewTab('https://finder.terra.money/columbus-5/address/terra15cdk3xzzy97wlx6t44gg98k5gs9jd2420zuk9jy43cgcn8vkpsls9d04jq')}>
                        Check burns on Explorer →
                    </button>
                    <button type="button" className="btn _btn_grey border-0 rounded-5 text-sm" onClick={() => openLinkInNewTab('https://finder.terra.money/columbus-5/address/terra15cdk3xzzy97wlx6t44gg98k5gs9jd2420zuk9jy43cgcn8vkpsls9d04jq')}>
                        Previous winner payouts →
                    </button>
                  </div>
                </div>

                {/*  How to play Starts */}
                <div className="how-toplay rounded-4 mt-5 overflow-hidden border-0">
                  <div className="_e_header  py-lg-3 py-2 d-flex justify-content-center align-items-baseline">
                    <h3 className="fw-semibold mb-0">How to play </h3>
                  </div>
                  <div className="e_body pt-4 pe-lg-5 pb-3">
                    <ol className="e_body_list">
                      <li className="ps-2 ps-lg-4 pe-2 mb-1 text-sm">Connect your compatible Wallet using link in the top right corner.</li>
                      <li className="ps-2 ps-lg-4 pe-2 mb-1 text-sm">Select your entries to purchase in the box above.</li>
                      <li className="ps-2 ps-lg-4 pe-2 mb-1 text-sm">Press BUY NOW and confirm your purchase in your wallet.</li>
                      <li className="ps-2 ps-lg-4 pe-2 mb-1 text-sm">Your wallet address is used as your entry and is
                        entered multiple times for muliple entries purchased in a single draw.</li>
                      <li className="ps-2 ps-lg-4 pe-2 mb-1 text-sm">Draws happen EVERY 24 HOURS at midnight UTC and a
                        single selected winner will receive their prize as a transfer back to the wallet
                        used for purchase.</li>
                    </ol>
                  </div>
                </div>
                {/*  How to play Ends */}

                <div className="d-flex align-items-center flex-column pb-4 pt-5">
                  {unableToPlay ?
                    (
                      <img className="img-fluid" src={PlayRequirements} alt="" />
                    )
                    :
                    (
                      <button className="btn btn-lg btn-light border-2 rounded-4 border-black btn-winlunk fw-light py-1 px-2 hd-sm position-relative btn-wallet mb-3" onClick={wallet.status === WalletStatus.NOT_CONNECTED ? connectWalletHandler : disconnectWalletHandler}>
                        {connectedWallet ? (
                          <>
                            {connectedWalletAddress.substring(0, 20) + "..."}
                          </>
                        ) : (
                          <>
                            Connect <span className="fw-bold">Compatible</span> Wallet
                          </>
                        )}
                      </button>
                    )
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*  Contents Ends */}

        <div className="position-absolute hide_on_small w-100 d-flex justify-content-between align-items-end footertoken overflow-x-hidden">
            <div className="_wrapper d-flex position-relative">
              <img className="position-absolute" src={Tokens} alt="" />
              <img className="position-absolute" src={Tokens} alt="" />
            </div>
        </div>
      </main>

      {/*  Footer Starts */}
      <div className="footer position-relative z-0 _bg_main py-4">
        <div className="_wrapper d-flex justify-content-between align-items-center flex-lg-row flex-column">
          <p className="text-xsm text-white-50 text-lg-start text-center">WinLUNC operates under legislation as declared by the Republic of Costa Rica.</p>
          <ul>
            <li className="text-decoration-none text-lg-end text-center"><a className="text-white-50 text-end text-xsm" href="https://www.wincryptoburncrypto.com">About Us</a></li>
            <li className="text-decoration-none text-lg-end text-center"><a className="text-white-50 text-end text-xsm" href="https://wincryptoburncrypto.gitbook.io/legal/product-guides/legals#terms-and-conditions">Terms & Conditions</a></li>
            <li className="text-decoration-none text-lg-end text-center"><a className="text-white-50 text-end text-xsm" href="https://wincryptoburncrypto.gitbook.io/legal/product-guides/legals#privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      {/*  footer ends */}

    </>
  );
}

export default Home;
