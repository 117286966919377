import { MsgExecuteContract, Coins, Coin, isTxError, Tx, Fee } from "@terra-money/feather.js";
import { ConnectResponse, SignResponse, WalletResponse } from "@terra-money/wallet-kit";

import { GameInfo, PriceInfo, RunDrawStatus } from "../models";

import { getGameInfo as getHostGameInfo, getPriceInfo as getHostPriceInfo, signTxn } from "utils/HostUtils";
import { getLCDClient, getChainID, getContractAddress, getOwnerAddress } from "../utils/TerraUtils";

// Note: Coin price is represented on the smart contract as a uint128 where 1.000000 = 1000000
//       So, we need to convert all coin prices and amounts to a float by dividing values by 1000000
//       when they are received!!

const COIN_DIVISOR = 1000000;

//
// Private Helper Functions
//

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const until = Date.now() + 1000 * 60 * 60;
const untilInterval = Date.now() + 1000 * 60;

const execTx = async (wallet: WalletResponse, connected: ConnectResponse, msg: any, fee: Fee, coins?: Coins.Input) => {
  const lcdClient = getLCDClient(wallet);
  const chainID = getChainID(connected);

  const contractAddress = getContractAddress(connected);
  const walletAddress = getOwnerAddress(connected);

  const execMsg = new MsgExecuteContract(walletAddress, contractAddress, msg, coins);

  console.log(`ContractService::execTx -> Network: ${connected.network}, chainID: ${chainID}, Contract Address: ${contractAddress}, Wallet Address: ${walletAddress}`);
  console.log(`ContractService::execTx Request -> ${JSON.stringify(execMsg)}`);

  const tx = await wallet.post({ chainID, fee, msgs: [execMsg]});
  console.log(`ContractService::execTx tx -> ${JSON.stringify(tx, null, 2)}`);

  if (!tx?.txhash)
    throw new Error("ContractService::execTx Transaction hash not found");

  while (true) {
    try {
      console.log(`ContractService::execTx Getting txInfo for: ${tx?.txhash}`);
      await lcdClient.tx.txInfo(tx?.txhash, chainID);

      return tx;
    } catch (e) {
      if (Date.now() < untilInterval) {
        await sleep(500);
      } else if (Date.now() < until) {
        await sleep(1000 * 10);
      } else {
        throw new Error(`Transaction queued. To verify the status, please check the transaction hash: ${tx?.txhash}`);
      }
    }
  }
}

export const ContractService = () => {
  //
  // Queries
  //

  const getPriceInfo = async (): Promise<PriceInfo> => {
    const priceInfo = await getHostPriceInfo();

    console.log("ContractService::getPriceInfo -> PRICES = ", JSON.stringify(priceInfo));

    return priceInfo;
  }

  const getGameInfo = async (wallet: WalletResponse, connected: ConnectResponse | undefined): Promise<GameInfo> => {
    // And if we are connected then get No of Owner Tickets from the contract for the connected wallet
    if (connected) {
      // Get the game info from the contract
      console.log("ContractService::getGameInfo -> CONNECTED");

      const gameInfo: GameInfo = await getLCDClient(wallet).wasm.contractQuery(getContractAddress(connected), { get_game_info: {owner: getOwnerAddress(connected)} });

      // Process the contract data into decimals using the COIN_DIVISOR
      gameInfo.total_paid_burn = Math.round((gameInfo.total_paid_burn ?? 0) / COIN_DIVISOR);
      gameInfo.total_paid_winners = Math.round((gameInfo.total_paid_winners ?? 0) / COIN_DIVISOR);

      // Ensure that the game winning payout is doubled to include what would be the burn amount!!
      gameInfo.game_winning_payout = Math.round((gameInfo.game_winning_payout ?? 0) / COIN_DIVISOR) * 2;

      console.log("ContractService::getGameInfo -> CONTRACT = ", gameInfo);

      return gameInfo;
    } else {
      // Get the game info from the host
      const gameInfo = await getHostGameInfo();

      // Ensure that the game winning payout is doubled to include what would be the burn amount!!
      gameInfo.game_winning_payout = (gameInfo.game_winning_payout ?? 0) * 2;

      console.log("ContractService::getGameInfo -> HOST", gameInfo);

      return gameInfo;
    }
  }

  const getRunDrawStatus = async (wallet: WalletResponse, connected: ConnectResponse | undefined): Promise<RunDrawStatus> => {
    // Get out of dodge early if we don't have a usable wallet
    if (!connected) throw new Error("No wallet is connected");

    // Get the current ticket price
    return await getLCDClient(wallet).wasm.contractQuery(getContractAddress(connected), { can_run_draw_on_current_game: {}});
  };

  //
  // Mutations
  //

  const buyTickets = async (wallet: WalletResponse, connected: ConnectResponse, gameId: number, amount: number) => {
    try {
      const signedTxnInfo = await signTxn(getOwnerAddress(connected), gameId, amount);
      console.log("ContractService::buyTickets -> Signed Txn = ", JSON.stringify(signedTxnInfo));

      const coinAmount = Math.round((signedTxnInfo.ticket.price * amount) / signedTxnInfo.coin.price) * COIN_DIVISOR;
      const feeAmount = Math.round(coinAmount * 0.01);
      const coin = new Coin(signedTxnInfo.coin.denom, coinAmount);
      const fee = new Fee(1000000, { uluna: feeAmount });
      console.log(`ContractService::buyTickets -> Coin = ${JSON.stringify(coin)}, Fee = ${JSON.stringify(fee)}`);

      const txn = { buy_tickets: { time_stamp: signedTxnInfo.timestamp,
                                   game_id: signedTxnInfo.game_id,
                                   amount: signedTxnInfo.amount,
                                   coin_price: `${(signedTxnInfo.coin.price * COIN_DIVISOR)}`,
                                   ticket_price: `${(signedTxnInfo.ticket.price * COIN_DIVISOR)}`,
                                   signature: signedTxnInfo.signature,
                                   public_key: signedTxnInfo.public_key }};

      console.log(`ContractService::buyTickets -> Txn = ${JSON.stringify(txn, null, 2)}`);

      const tx =  await execTx(wallet, connected, txn, fee, coin.toString());
      return { txhash: tx.txhash, success: true };
    } catch (err) {
      console.log(`ContractService::buyTickets -> Error: ${JSON.stringify(err, null, 2)}`);
      return { txhash: null, success: false };
    }
  }

  return {
    // Queries
    getGameInfo,
    getPriceInfo,
    getRunDrawStatus,

    // Mutations
    buyTickets
  };
}


